import api from "./api";
import store from "./store";

const mods = [71, 67, 59, 53, 47, 43, 41, 37, 29, 23, 19, 17, 13, 7, 3];
//eslint-disable-next-line
export const isNitValid = (nit: any) => {
    nit = nit.replace(/\./g, "");
    nit = nit.replace(" ", "");
    nit = nit.replace("-", "");

    const posiciones: number[] = [];

    
    if(nit.length < 10){
        const nit_mod = nit.length < 15 ? "0".repeat(15 - nit.length) + nit : nit;

        for(let i=0; i < 15; i++){
            posiciones.push(nit_mod[i] * mods[i]);
        }

        const total = posiciones.reduce(function(previous, current){
            return previous + current
        }, 0);

        const residuo = total % 11;

        let digito_verificacion = 0;

        if(residuo == 0){
            digito_verificacion = 0;
        }
        else if(residuo == 1){
            digito_verificacion = 1;
        }
        else {
            digito_verificacion = 11 - residuo;
        }

        const largo = nit.length + 1;

        return [
            nit, digito_verificacion, (largo == 10) ? true : false
        ];

    } 
    else if(nit.length == 10){
        for(let i = 0; i < 9; i++){
            posiciones.push(nit[i] * mods[i+6]);
        }
        let v = posiciones.reduce(function(previous, current){
            return previous + current
        }, 0);

        v = v % 11;

        if(v >= 2){
            v = 11 - v;
        }

        if(v == nit[9]){
            nit = nit.replace(/\d$/, " ");
            return [nit, v, true];
        }else{
            v =  nit.substr(-1);
            nit = nit.replace(/\d$/, " ");
            return [nit, v, false];
        }
    }else{
        const v =  nit.substr(-1);
        nit = nit.replace(/\d$/, " ");
        return [nit, v, false];
    }
}

export const setNit = (nitResponse:string, verificador:string) => {
    let nit = nitResponse;
    let nitAux = "";
    for(let i=0; i<nit.length; i+=3){
        nitAux = nitAux+(nit.slice(i,i+3));
        if(i!=6){
            nitAux = nitAux+"."
        }
        else{
            nit = nitAux+"-"+verificador;
        }
    }
    return nit;
}
/* eslint-disable */

export function validatePhone(phone:string):boolean{
    return  /^[\(]?[\+]?(\d{2}|\d{3})?[\s](\d{2}?[\s]|\d{3}?[\s])((\d{8}|\d{7})|(\d{3}?[\s]){2}\d{3}?[\s]|(\d{2}?[\s]){3}\d{2}?[\s]|(\d{4}?[\s]){1}\d{4}?[\s])|\d{10}|[\s](\d{7})|[\+]\d{12}|[\+](\d{2})[\s](\d{10})$/.test(phone);
}

export function validateTasas(tasa:string):boolean{

    return /^((100)|(\d?\d?)(\.\d?\d?\d?\d?\d?)?)$/.test(tasa) && parseFloat(tasa) > 0;
}
export function validateEmail(email:string):boolean{
    return /[a-z0-9_]+(?:\.[a-z0-9_]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email); 
}

export function validateTasas5Decimales(tasa:string):boolean{

    return /^((100)|(\d?\d?)(\.\d?\d?\d?\d?\d?)?)$/.test(tasa) && parseFloat(tasa) > 0;
}

// Formateo de monedas

export function formatNumber(n:any) {
    // format number 1000000 to 1.234.567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export function formatNumberDecimal(n:any) {
    // format number 1000000 to 1.234.567,0000
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    
    return numberPart.replace(thousands, ".") + (decimalPart ? "," + decimalPart : "");
}

export function formatCurrency(input: string, blur?: string) : string {
    let simbolo = ""
    var input_val = input;
    

    // don't validate empty input
    if (input_val === "") { return ''; }

    // check for decimal
    if (input_val.indexOf(",") >= 0) {

        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        var decimal_pos = input_val.indexOf(",");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = formatNumber(left_side);

        // validate right side
        // right_side = formatNumber(right_side);

        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
            right_side += "0000";
        }

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 5);

        // join number by .
        input_val = simbolo + left_side + right_side;

    } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = formatNumber(input_val);
        input_val = simbolo + input_val;

        // final formatting
        if (blur === "blur") {
            input_val += ",0000";
        }
    }

    // send updated string to input
    input = input_val;
    return input;
}

export async function generatePDF(type: string, name: string, payload = null) {
    const payloadData = {
      type,
      payload,
    };
  
    store.dispatch('spinner/showSpinner', { state: true, text: 'Cargando..' });
  
    try {
      const { status, data } = await api.generatePDF(payloadData);
  
      store.dispatch('spinner/showSpinner', { state: false, text: null });
  
      const date = new Date();
      const formattedDate = date.toISOString().replace(/[-:.]/g, '').slice(0, 15);
  
      if (status === 201) {
        store.dispatch('snack/snack', { text: 'El archivo se está procesando', type: 'success' });
      } else if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}_${formattedDate}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error('Fallo la descarga');
      }
    } catch (error) {
      store.dispatch('spinner/showSpinner', { state: false, text: null });
      throw error;
    }
}