import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "q-pa-md",
  style: {"background-color":"#F9F9F9"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Snackbar = _resolveComponent("Snackbar")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_PrivacityNotice = _resolveComponent("PrivacityNotice")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_layout, { view: "hHh LpR fff" }, {
      default: _withCtx(() => [
        _createVNode(_component_Navbar),
        ($setup.hideSidebar)
          ? (_openBlock(), _createBlock(_component_Sidebar, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view),
        _createVNode(_component_Spinner),
        _createVNode(_component_Snackbar),
        _createVNode(_component_Footer),
        ($setup.showPrivacityDialog)
          ? (_openBlock(), _createBlock(_component_PrivacityNotice, { key: 1 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}