interface Spinner {
    state: boolean | null;
    text: string | null;
}

interface RootState {
    spinner: Spinner;
}

export default {
    namespaced: true,
    state: (): RootState => ({
        spinner: {
            state: null,
            text: null,
        },
    }),
    getters: {
        getSpinner: (state: RootState) => {
            return state.spinner;
        },
    },
    mutations: {
        SET_SPINNER(state: RootState, payload: Spinner) {
            state.spinner.state = payload.state;
            state.spinner.text = payload.text;
        },
    },
    actions: {
        // eslint-disable-next-line
        // @ts-ignore
        showSpinner({ commit }, payload: Snack) {
            commit('SET_SPINNER', payload);
        },
        
    },
};