<template>
    <q-dialog
        v-model="spinner.state"
        persistent
    >
      <q-card class="flex flex-center column q-py-md" style="width: 300px">
        <q-img 
            src="/images/spinner.gif"
            width="80px"
        />
        <q-card-section>
            <p>{{ spinner.text }}</p>
        </q-card-section>
      </q-card>
    </q-dialog>
</template>
<script>
import store from '@/store';
import { computed } from 'vue';
export default({
    name: 'SpinnerComponent',
    setup() {
        return{
            spinner: computed(() => store.getters['spinner/getSpinner']),
        }
    },
})
</script>
<style lang="scss" scoped>


</style>
