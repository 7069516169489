
import { onBeforeMount, ref , computed } from 'vue'
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import Sidebar from './components/Sidebar.vue'
import store from '@/store/index'
import router from '@/router/index'
import Snackbar from '@/components/SnackComponent.vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { useRoute } from 'vue-router'
import PrivacityNotice from './components/PrivacityNotice.vue'
import Spinner from './components/Spinner.vue'

export default {
  name: 'LayoutDefault',

  components: {
    Navbar,
    Footer,
    Sidebar,
    Snackbar,
    PrivacityNotice,
    Spinner
  },
  setup () {

    const route = useRoute();
    const hideSidebar = computed(() => route.meta.hideSidebar !== true);
    const $q = useQuasar();
    
    const showPrivacityDialog = computed(() => {
      const isAuthenticated = store.state.user != null;
      const hasAcceptedPrivacy = store.state.privacity_check == 1;
      const shouldShow = isAuthenticated && !hasAcceptedPrivacy;
      return shouldShow;
    });

    onBeforeMount(() => {
      // cambio de idioma en la tablas
      $q.lang.table.allRows = 'Todo'
      setInterval(() => {
          if (store.state.user == null) {
            return;
          }
          else{
            let expireTime: Date = new Date(store.state.expireTime != null  ? store.state.expireTime  : '');
            
            if ( Date.now() > expireTime.getTime()) {
              store.commit('logout');
              router.push('/login');
  
            }
          }
        }, 5 * 1000);

        var time: number;
        window.onload = resetTimer;
        
        // DOM Events
        document.onmousemove = resetTimer;
        document.onkeydown = resetTimer;

        function logout() {
          store.commit('logout');
          router.push('/login');
          
        }

        function resetTimer() {
          if(store.state.user == null){
            return;
          }
          else{
            clearTimeout(time);
            time = setTimeout(logout, 300000);
          }
        }
    })
    return {
      leftDrawerOpen: ref(false),
      hideSidebar,
      showPrivacityDialog
    }
  }
}
