
import {mapState} from 'vuex'
import store from '@/store/index'
import router from '@/router';
import API from '@/api'
import { computed, onBeforeMount, onMounted, reactive, ref} from 'vue'
import SidebarRight from './TutorialSideBar.vue'
import {formatCurrency} from "@/utils"
export default {
    computed: mapState(['user']),
    name:'RCNavbar',
    setup () {
        let showSideBarTutorial = ref(false);
        let isMobile = ref(false);
        let menu = ref(false);
        const modalBuscoFactoring = ref(false);
        
        interface Business {
            id: string, 
            nombre: string,
        }
        let businessArray = ref<Business[]>([]);

        const periodicidadOptions = [
            { value: 1, label: 'Cada 1 semana' },
            { value: 2, label: 'Cada 2 semanas' },
            { value: 3, label: 'Cada 3 semanas' },
            { value: 4, label: 'Cada 1 mes' },
            { value: 5, label: 'Cada 2 meses' },
            { value: 6, label: 'Cada 3 meses' },
            { value: 7, label: 'Cada 4 meses' },
            { value: 8, label: 'Cada 5 meses' },
            { value: 9, label: 'Cada 6 meses' },
        ];
        
        interface formData {
            nombre: string,
            nit: string,
            empresa: string[],
            monto: string,
            plazo: string,
            periodicidad: string,
        }
        const formBuscoFactoring : formData = reactive({
            nombre: '',
            nit: '',
            empresa: [],
            monto: '',
            plazo: '',
            periodicidad: ''
        });

        const formRules = {
            nombreRule: [
                (v:string) => !!v || 'Nombre de cliente es requerido',
                (v:string) => /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(v) || 'Ingrese solo letras',
                (v:string) => v.length <= 255 || 'Nombre no puede exceder los 255 caracteres'
            ],
            nitRule: [
                (v:string) => !!v || "Ingrese NIT",
                (v:string) => v !== '' || "Ingrese NIT",
            ],
            montoRule: [
                (v:string) => !!v || 'Monto de facturación es requerido',
                (v:string) => !/[^\d.,]/gim.test(v) || "Tiene que ser solo números, coma o punto",
                (v:string) => /^(?:\d{1,3}(?:\.\d{3})*(?:,\d+)?$|\d+)$/.test(v) || "Formato incorrecto",
                (v:string) => v.length > 3 || "Valor ingresado es muy bajo.",
                (v:string) => v.length < 20 || "Valor ingresado es demasiado alto."
            ],
            plazoRule: [
                (v:string) => !!v || 'Ingrese plazo de facturación',
                (v:string) => !/[^\d]/gim.test(v) || "Tiene que ser solo numeros",
                (v:string) => v.length <= 3 || "Valor ingresado es demasiado alto"
            ],
            periodicidadRule: [
                (v:string) => !!v || 'Ingrese periodicidad de facturación'
            ],
            empresaRule: [
                (v:string) => !!v || 'Ingrese empresa'
            ]
        };

        const toggleShowSideBar= () => {
            if(!store.state.tutorial){
                store.commit("setVideoTutorial", 1);
                /* eslint-disable */
                //@ts-ignore
                API.changeTutorialVideo(store.state.user.id);
            }
            showSideBarTutorial.value = !showSideBarTutorial.value;
        };

        async function toggleModal(){
            await getEmpresas();
            modalBuscoFactoring.value = true;
        };

        const isButtonPress = computed(() => {
            return showSideBarTutorial
        });

        function onSubmit(){
            /* eslint-disable */
            //@ts-ignore
            API.buscoFactoring(store.state.user.id, formBuscoFactoring.nombre, formBuscoFactoring.nit, formBuscoFactoring.empresa.id, formBuscoFactoring.monto, formBuscoFactoring.periodicidad.value, formBuscoFactoring.plazo).
                then((response) => {
                    const res = response.data;
                    if(res.status == 200){
                        console.log(res.status);
                        modalBuscoFactoring.value = false;
                        //alerta
                        store.dispatch('snack/snack', { text: res.message, type: 'success' });
                        // reinicio de campos 
                        formBuscoFactoring.nombre = '';
                        formBuscoFactoring.nit = '';
                        formBuscoFactoring.empresa = [];
                        formBuscoFactoring.monto = '';
                        formBuscoFactoring.plazo = '';
                        formBuscoFactoring.periodicidad = '';
                    }
                }).catch((err) => {
                    const res = err.response.data;
                    // parametros equivocados o ya ingresados
                    if(res.status == 422){
                        let mensaje = '';
                        // verificar que tipo de error es
                        Object.values(res.data).forEach(element => {
                            mensaje += element + '.' + ' ';
                        });
                        store.dispatch('snack/snack', { text: mensaje, type: 'error' });

                    }
                    else{
                        modalBuscoFactoring.value = false;
                        //alerta
                        store.dispatch('snack/snack', { text: res.message, type: 'error' });

                    }
                });
        }
         
        function blurEvent(form:string, campo:string, value:string) {
            const formattedValue = formatCurrency(value, 'blur');
            //@ts-ignore
            form[campo] = formattedValue;
        }

        function validateForm() {
            let nombreValido = validarNombre(formBuscoFactoring.nombre);
            let nitValido = !!formBuscoFactoring.nit;
            let empresaValida = Object.keys(formBuscoFactoring.empresa).length > 0;
            let montoValido = validarMonto(formBuscoFactoring.monto);
            let plazoValido  = validarPlazo(formBuscoFactoring.plazo);
            let periodicidad  = !!formBuscoFactoring.periodicidad;
            return nombreValido && nitValido && empresaValida && montoValido && plazoValido && periodicidad;
        }

        function validarNombre(nombre:string){
            let isEmpty = !!nombre;
            let isString = /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(nombre);
            let isLarge = nombre.length <= 255;

            return isEmpty && isString && isLarge;
        }

        function validarMonto(monto:string){
            let isEmpty = monto !== '' && monto !== undefined && monto !== null;
            let isNumber = !/[^\d.,]/gim.test(monto);
            let isValid = /^(?:\d{1,3}(?:\.\d{3})*(?:,\d+)?$|\d+)$/.test(monto);
            let isRange = monto.length >= 3 && monto.length < 20;

            return isEmpty && isNumber && isValid && isRange;
        }

        function validarPlazo(plazo:string){
            let isEmpty = !!plazo;
            let isNumber = !/[^\d.,]/gim.test(plazo);
            let isRange = plazo.length <= 3;

            return isEmpty && isNumber && isRange;
        }

        onBeforeMount(() => {
            showSideBarTutorial.value = false;
        });

        const onResize = () => {
			if (window.innerWidth < 960)
				isMobile.value = true;
			else
				isMobile.value = false;
		}

		onMounted(() => {
			onResize();
			window.addEventListener('resize', onResize);
		});

        async function getEmpresas(){
            const empresas = await API.getEmpresasUsuario();
            businessArray.value = [{ id: null, nombre: 'No seleccionar empresa' }, ...empresas.data.empresas];
        }
        
        return {
            onMainClick () {
                // console.log('Clicked on main button')
            },

            onItemClick () {
                API.logout().then(() => {
                    store.commit('logout');
                    router.push('/login');
                });

            },
            toggleShowSideBar, 
            showSideBarTutorial,
            isButtonPress,
            isMobile,
            menu,
            businessArray,
            formBuscoFactoring,
            modalBuscoFactoring,
            toggleModal,
            onSubmit,
            blurEvent,
            formRules,
            validateForm,
            periodicidadOptions,
        }
    },
    components :{
        SidebarRight,
    }
}
